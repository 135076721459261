import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/about/Hero"
import IntroWithIcon from "../components/about/IntroWithIcon"
import ContentWithImage from "../components/about/ContentWithImage"
import LinksRow from "../components/about/LinksRow"
import ColouredContentImage from "../components/about/ColouredContentImage"
import DisplayTeam from "../components/about/DisplayTeam"
import FinalMessage from "../components/about/FinalMessage"

const about = props => {
  const hero = props?.data?.hero?.template?.aboutPage
  const introWithIcon = props?.data?.introWithIcon?.template?.aboutPage
  const contentWithImage = props?.data?.contentWithImage?.template?.aboutPage
  const linksRow = props?.data?.linksRow?.template?.aboutPage
  const colouredContentImage =
    props?.data?.colouredContentImage?.template?.aboutPage

  const displayTeam = props?.data?.displayTeam?.template?.aboutPage
  const finalMessage = props?.data?.finalMessage?.template?.aboutPage

  return (
    <Layout>
      <Seo title="About" />
      <Hero data={hero} />
      <IntroWithIcon data={introWithIcon} />
      <ContentWithImage data={contentWithImage} />
      <LinksRow data={linksRow} />
      <ColouredContentImage data={colouredContentImage} />
      <DisplayTeam data={displayTeam} />
      <FinalMessage data={finalMessage} />
    </Layout>
  )
}

export const aboutQuery = graphql`
  query aboutTempPage($id: String!) {
    # seoInfo: wpPage(id: { eq: $id }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          aboutPage {
            heroImageTitle
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    introWithIcon: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          aboutPage {
            introWithIconTitle
            introWithIconContent
            introWithIconIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }
        }
      }
    }

    contentWithImage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          aboutPage {
            contentWithImageBigContent
            contentWithImageSmallContent
            contentWithImageImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }
        }
      }
    }

    linksRow: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          aboutPage {
            linksRowButtonText
            linksRowButtonSlug
            linksRowLinks {
              linkText
              linkSlug
            }
            linksRowIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }
        }
      }
    }

    colouredContentImage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          aboutPage {
            colouredContentWithImageTitle
            colouredContentWithImageContent
            colouredContentWithImageButtonText
            colouredContentWithImageButtonSlug
            colouredContentWithImageImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            colouredContentWithImageIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }

    displayTeam: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          aboutPage {
            displayTeamMembers
          }
        }
      }
    }

    finalMessage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_About {
          aboutPage {
            finalMessageContent
            finalMessageIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default about
