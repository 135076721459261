import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { colors, medWrapper, H2Peach, B1Peach } from "../../styles/helpers"
import { Link } from "gatsby"

const ColouredContentImage = ({ data }) => {
  const imageBottomDisplay = getImage(
    data.colouredContentWithImageImage.localFile.childImageSharp.gatsbyImageData
  )
  const imageBottomAlt = data.colouredContentWithImageImage.altText

  const iconDisplay = getImage(
    data.colouredContentWithImageIcon.localFile.childImageSharp.gatsbyImageData
  )
  const iconAlt = data.colouredContentWithImageIcon.altText

  return (
    <StyledSection>
      <div className="wrapper">
        <div className="content">
          <div className="content__container">
            <div>
              <h2>{data.colouredContentWithImageTitle}</h2>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: data.colouredContentWithImageContent,
              }}
            />
            <div>
              <Link to={`/${data.colouredContentWithImageButtonSlug}`}>
                {data.colouredContentWithImageButtonText}
              </Link>
            </div>
            <div className="bg-icon">
              <GatsbyImage
                image={iconDisplay}
                alt={iconAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <div className="content__image">
            <GatsbyImage
              image={imageBottomDisplay}
              alt={imageBottomAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  margin-top: 5rem;

  .wrapper {
    ${medWrapper};
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__container {
      position: relative;
      width: 100%;
      background-color: ${colors.black};
      margin-bottom: 2.79rem;
      padding: 4rem 2rem;

      @media (min-width: 768px) {
        width: calc(66.666666% - 0.5rem);
        margin-bottom: 0;
        padding: 6rem 9rem;
      }

      h2 {
        ${H2Peach};
        margin-top: 0;
      }

      p {
        ${B1Peach};
      }

      a {
        ${B1Peach};
        text-transform: uppercase;

        &:hover {
          color: ${colors.colorAccent};
        }
      }
      .bg-icon {
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        width: 15rem;

        @media (min-width: 768px) {
          width: 45%;
        }

        @media (min-width: 1025px) {
          width: 31rem;
        }
      }
    }

    &__image {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(33.333333% - 1rem);
        margin-left: 1.5rem;
      }
    }
  }
`

export default ColouredContentImage
