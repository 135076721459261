import React from "react"
import styled from "styled-components"
import { H1White, medWrapper } from "../../styles/helpers"
import HeroImage from "./HeroImage"

const Hero = ({ data }) => {
  const bgImg = data?.heroImage
  return (
    <StyledSection>
      {bgImg && <HeroImage bgImg={bgImg} />}
      <div className="home-hero-title">
        <h1>{data?.heroImageTitle}</h1>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  position: relative;
  width: 100%;
  height: 30rem;

  @media (min-width: 768px) {
    height: 40rem;
  }

  @media (min-width: 1025px) {
    height: 44rem;
  }

  .home-hero-title {
    ${medWrapper};
    position: absolute;
    right: 0;
    bottom: 5rem;
    left: 0;
    z-index: 10;

    @media (min-width: 768px) {
      bottom: 7.5rem;
      margin: auto;
    }

    @media (min-width: 1025px) {
      bottom: 7rem;
    }

    h1 {
      ${H1White};
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
`

export default Hero
