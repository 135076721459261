import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors, H3DarkBlue, standardWrapper } from "../../styles/helpers"

const FinalMessage = ({ data }) => {
  const iconDisplay = getImage(
    data.finalMessageIcon.localFile.childImageSharp.gatsbyImageData
  )
  const iconAlt = data.finalMessageIcon.altText

  return (
    <StyledDiv>
      <div className="wrapper">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.finalMessageContent }}
        />
        <div className="bg-icon">
          <GatsbyImage
            image={iconDisplay}
            alt={iconAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  padding-top: 5rem;
  padding-bottom: 10rem;
  background-color: rgba(221, 211, 201, 0.4);

  @media (min-width: 768px) {
    padding-bottom: 12rem;
  }

  @media (min-width: 1025px) {
    padding-bottom: 7.5rem;
  }

  .wrapper {
    ${standardWrapper};
    position: relative;

    @media (min-width: 1025px) {
      max-width: 80rem;
    }
  }

  .content {
    text-align: center;
    p,
    a {
      ${H3DarkBlue};
    }

    a:hover {
      color: ${colors.colorAccent};
    }
  }

  .bg-icon {
    position: absolute;
    right: 0;
    bottom: -10rem;
    width: 20rem;

    @media (min-width: 768px) {
      bottom: -10rem;
      width: 24rem;
    }

    @media (min-width: 1025px) {
      right: -22.5rem;
      bottom: -2rem;
      width: 28.25rem;
    }
  }
`

export default FinalMessage
