import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Brown, Btn1One, medWrapper } from "../../styles/helpers"
import { Link } from "gatsby"

const LinksRow = ({ data }) => {
  const imageDisplay = getImage(
    data.linksRowIcon.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.linksRowIcon.altText
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="links">
          <div className="links__icon">
            <GatsbyImage
              image={imageDisplay}
              alt={imageAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className="links__container">
            {data.linksRowLinks.map((link, index) => {
              return (
                <LinkItem key={index} to={`/${link.linkSlug}`}>
                  <span>{link.linkText}</span>
                </LinkItem>
              )
            })}
          </div>
        </div>
        <div className="btn-resourses">
          <Link to={`/${data.linksRowButtonSlug}`}>
            {data.linksRowButtonText}
          </Link>
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__icon {
      width: calc(100%);
      @media (min-width: 768px) {
        width: calc(100% * (2 / 5));
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      width: calc(100%);

      @media (min-width: 768px) {
        width: calc(100% * (3 / 5));
      }
    }
  }

  .btn-resourses {
    width: 100%;
    margin-top: 5rem;
    text-align: center;

    a {
      ${Btn1One};
    }
  }
`

const LinkItem = styled(Link)`
  ${B1Brown};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem;
  border-radius: 4px;
  transition: all 0.3s ease-in;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(100% / 3);
    padding: 3rem;
  }

  @media (min-width: 1025px) {
    width: calc(100% / 3);
    padding: 3rem;
  }

  span {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(221, 211, 201, 0.35);
  }
`

export default LinksRow
