import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H2DarkBlue, medWrapper, B3DarkBlue } from "../../styles/helpers"

const IntroWithIcon = ({ data }) => {
  const imageDisplay = getImage(
    data.introWithIconIcon.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.introWithIconIcon.altText
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>
            <span className="title__icon">
              <GatsbyImage
                image={imageDisplay}
                alt={imageAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </span>
            <span className="title__text">{data.introWithIconTitle}</span>
          </h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.introWithIconContent }}
        />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};

    @media (min-width: 1025px) {
      max-width: 114.2rem;
    }
  }

  .title {
    width: 100%;

    h2 {
      ${H2DarkBlue};
      display: flex;
      align-items: center;

      .title__icon {
        display: inline-block;
        width: 15rem;
      }

      .title__text {
        padding-left: 3.5rem;
      }
    }
  }

  .content {
    width: 100%;

    p {
      ${B3DarkBlue};
    }
  }
`

export default IntroWithIcon
