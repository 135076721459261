import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import {
  B1DarkBlue,
  B2DarkBlue,
  H2DarkBlue,
  medWrapper,
} from "../../styles/helpers"

const getData = graphql`
  {
    members: allWpTeamMember(sort: { order: ASC, fields: date }) {
      edges {
        node {
          id
          title
          ourTeam {
            details
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }
  }
`

const DisplayTeam = ({ data }) => {
  const teamData = useStaticQuery(getData)
  const members = teamData?.members?.edges ? teamData.members.edges : []
  if (!data.displayTeamMembers && members.length <= 0) return
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>Meet Our Team</h2>
        </div>
        <div className="members">
          {members.map((member, index) => {
            const imageDisplay = getImage(
              member.node.ourTeam.image.localFile.childImageSharp
                .gatsbyImageData
            )
            const imageAlt = member.node.ourTeam.image.altText
            return (
              <MemberCard
                key={member.node.id}
                firstcard={index === 0 || index % 3 === 0}
              >
                <div className="team-image">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div>
                  <h3>{member.node.title}</h3>
                  <p className="title">{member.node.ourTeam.title}</p>
                  {member.node.ourTeam.details && (
                    <p className="details">{member.node.ourTeam.details}</p>
                  )}
                </div>
              </MemberCard>
            )
          })}
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;

    h2 {
      ${H2DarkBlue};
    }
  }

  .members {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`

const MemberCard = styled.div`
  width: 100%;
  margin-bottom: 4.5rem;

  @media (min-width: 768px) {
    width: calc(33.333333% - 1rem);
    margin-left: ${props => (props.firstcard ? "0rem" : "1.5rem")};
  }

  @media (min-width: 1025px) {
    width: calc(33.333333% - 1rem);
    margin-left: ${props => (props.firstcard ? "0rem" : "1.5rem")};
  }

  .team-image {
    margin-bottom: 1.8rem;
  }

  h3 {
    ${B1DarkBlue};
    margin: 0;
  }

  p.title {
    ${B1DarkBlue};
    margin-bottom: 1.5rem;
  }

  p.details {
    ${B2DarkBlue};
  }
`

export default DisplayTeam
